/*
Theme Name: みくり
Author: warrior_ito
Description: .
Version: 1.0.0
License: GNU General Public License
License URI: https://www.gnu.org/licenses/gpl.html
*/

@charset "utf-8";
@import "moto_style";
$theme_color: #FF3300;
$link_color: blue;
$text_color: #000;
$base_color: #FFFDF4;
//$theme_fonts: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Yu Gothic", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
$theme_fonts: YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
//$headline_fonts: Georgia,游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
$headline_fonts: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
$content_width:1100px;

@mixin cf{
  &:after{
    content:" ";
    display:block;
    clear:both;
  }
}

body{
  font-family: $theme_fonts;
  color: $text_color;
  line-height:1.7;
  font-size:16px;
  background-color: #FFFDF4;
}
